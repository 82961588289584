import React from 'react'
import { Block } from "./Block"
import { RichText } from "./RichText"
import { EntityDetails } from "./EntityDetails"
import { PageHeading, SectionHeading } from "./Headings"
import { graphql } from "gatsby"
import { slug } from "../gatsby/services.create"
import { ActionLink, alternativeStyles } from "./CallToAction";
import { join } from "../styles/styles"
import { Image } from "./Image"

export function Service({service, className, innerWrapperStyle, showExcerpt}) {
  if (showExcerpt)
    return <ServiceExcerpt service={service} className={className} />
  return <Block className={className} innerWrapperStyle={innerWrapperStyle}>
    <EntityDetails entity={service} />
    <PageHeading>{service.name}</PageHeading>
    <RichText body={service.body} />
  </Block>
}

export function ServiceExcerpt({service, className}) {
  return <div className={join(className, 'flex flex-col')}>
    {service.image && <Image image={service.image} className='object-cover overflow-hidden' style={{ height: '14rem' }} />}
    <SectionHeading color='text-primary' className='mt-8'>{service.name}</SectionHeading>
    <RichText body={service.excerpt} className='flex-grow'/>
    <div className='mt-4'><ActionLink target={slug(service.key)} styles={alternativeStyles}>Tell me more</ActionLink></div>
  </div>
}

export const query = graphql`
  fragment Service on ContentfulSiteService {
    __typename
    key
    name
    excerpt {
      raw
    }
    body {
      raw
      references {
        ... on ContentfulAsset {
          ...ImageContent
        }
        ... on ContentfulEntry {
          contentful_id
        }
      }
    }
    image {
      ...ImageContent
    }
    testimonials {
      ...Testimonial
    }
  }
`