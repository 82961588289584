import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Layout } from "../layouts/Site"
import { AssertPage } from "../components/AssetContext"
import { HeaderPage, PageWidth, Section } from "../components/Containers"
import { Component } from "../components/Component"
import { Image } from "../components/Image"
import { RichText } from "../components/RichText"
import { pageHeadingStyles } from "../components/Headings"
import { sectionEndLine } from "../styles/styles"
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";

export default function BrandPage({ location }) {
  const { page, defaultMetadata } = useStaticQuery(query)

  return <Layout title={page.name}>
    <Helmet>
      <meta name="description" content={page.metaDescription || defaultMetadata.metaDescription} />
    </Helmet>
    <AssertPage id={page.contentful_id} url={location.pathname} />
    {page.contents && page.contents.map(createTopLevelComponent)}
  </Layout>
}

function createTopLevelComponent(content) {
  if (content.key === 'brand')
    return <BrandSection content={content} />

  return <PageWidth>
    <Section>
      <Component content={content} />
    </Section>
  </PageWidth>
}

function BrandSection({ content }) {
  const richTextStyles = {
    ...pageHeadingStyles,
    [BLOCKS.EMBEDDED_ASSET]: "max-w-xs"
  }

  return <>
    <HeaderPage className={sectionEndLine}>
      <Section>
        <Image image={content.image} className='bg-page'/>
      </Section>
    </HeaderPage>
    <PageWidth>
      <Section className='pt-8'>
        <RichText styles={richTextStyles} body={content.body} />
      </Section>
    </PageWidth>
  </>
}

const query = graphql`{
  page: contentfulSiteContentList(key: {eq: "brand"}) {
    key
    name
    contentful_id
    contents {
      ... on ContentfulSiteContent {
        ...Content    
      }
    }
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  }
}`