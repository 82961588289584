import React from 'react'
import { Image } from "./Image"
import { graphql } from "gatsby";

export function Partner({partner, className}) {
  return <a href={partner.url} className={className} aria-label={partner.name}>
    <Image image={partner.logo} />
  </a>
}

export const query = graphql`
  fragment Partner on ContentfulSitePartner {
    __typename
    key
    name
    url
    logo {
      ...ImageContent
    }
  }
`