import React from "react"
import { Service } from "./Service"
import { Content } from "./Content"
import { Partner } from "./Partner"
import { Testimonial } from "./Testimonial"

export function Component({ content, className, innerWrapperStyle }) {
  if (content.__typename === "ContentfulSiteService")
    return <Service
      service={content}
      className={className}
      innerWrapperStyle={innerWrapperStyle}
      showExcerpt />

  if (content.__typename === "ContentfulSitePartner")
    return <Partner
      partner={content}
      className={className} />

  if (content.__typename === "ContentfulSiteTestimonial")
    return <Testimonial testimonial={content} className={className} />

  return <Content
    content={content}
    className={className}
    innerWrapperStyle={innerWrapperStyle} />
}